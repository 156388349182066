<ng-template #copyModal let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">New Channel</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        
        <div class="form-group row">

            <div class="col-lg-12 mt-4">

                <div class="alert alert-warning alert-warning-light alert-warning-sm">
                    
                    <p>
                        Submitting this will create a brand new channel with the participants already in the channel. 
                        <strong>Please, note that this will not copy the messages</strong>
                    </p>

                </div>

            </div>

            <div class="col-lg-12 mt-1">
                
                <label> New Channel Name </label>
                <app-input 
                    [type]="'text'" 
                    [class]="'form-control'" 
                    [id]="'new_channel_name'"
                    [value]="new_channel_name"
                    [placeholder]="''"
                    [(inputModel)]="new_channel_name">
                </app-input>
    
            </div>

        </div>

       

    </div>
    <div class="modal-footer">
        <button (click)="onCopyLog($event)" class="btn ripple btn-primary" type="button">Yes, Copy Channel</button>
        <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
    </div>

</ng-template>

<a ngbDropdownItem href="javascript:;" (click)="open(copyModal)"><i class="fe fe-mail mr-1"></i>Copy Channel</a>