<!-- Horizonatal menu-->

<div class="main-navbar hor-menu sticky" (scroll)="onWindowScroll($event)">
    <div class="container">

        <ul class="nav">
            <!-- 1st Level Menu -->
            <li class="nav-item" *ngFor="let menuItem of menuItems"
                [ngClass]="{active: menuItem.active, 'show': menuItem.active}">
                <!-- has-Link -->
                <a class="nav-link" [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active"
                    *ngIf="menuItem.type === 'link' " (click)="toggleNavActive(menuItem)">
                    <i class="{{menuItem.icon}} sidemenu-icon"></i>
                    {{menuItem.title}}
                </a>
                <!-- has-Sub -->
                <a class="nav-link with-sub" [routerLink]="menuItem.type ? null: [menuItem.path]"
                    routerLinkActive="active" *ngIf="menuItem.type === 'sub'" (click)="toggleNavActive(menuItem)">
                    <i class="{{menuItem.icon}} sidemenu-icon"></i>
                    {{menuItem.title}}
                </a>
                <!-- 2nd Level menu -->
                <ul class="nav-sub" *ngIf="menuItem.children">
                    <li class="nav-sub-item" *ngFor="let childrenItem of menuItem.children"
                        [ngClass]="{active: childrenItem.active, 'show': childrenItem.active}">

                        <!-- link -->
                        <a class="nav-sub-link" [routerLink]="!childrenItem.type ? null : [childrenItem.path]"
                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            *ngIf="childrenItem.type === 'link' " (click)="toggleNavActive(childrenItem)">
                            {{childrenItem.title}}
                        </a>
                        <!-- sub -->
                        <a class="nav-sub-link with-sub" [routerLink]="childrenItem.type ? null : [childrenItem.path]"
                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            *ngIf="childrenItem.type === 'sub' " (click)="toggleNavActive(childrenItem)">
                            {{childrenItem.title}}
                        </a>

                        <!-- 3rd Level menu -->
                        <ul class="nav-sub" *ngIf="childrenItem.children">
                            <li class="nav-sub-item" *ngFor="let childrenSubItem of childrenItem.children"
                                [ngClass]="{'show': childrenSubItem.active}">
                                <!-- link -->
                                <a class="nav-sub-link"
                                    [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
                                    *ngIf="childrenSubItem.type === 'link'" [routerLinkActiveOptions]="{exact : true}">
                                    {{childrenSubItem.title}}
                                </a>
                                <!-- sub -->
                                <a class="nav-sub-link with-sub"
                                    [routerLink]="childrenSubItem.type ? null : [childrenSubItem.path]"
                                    *ngIf="childrenSubItem.type === 'sub'" (click)="toggleNavActive(childrenSubItem)">
                                    {{childrenSubItem.title}}
                                </a>
                                <!-- 4th Level menu -->
                                <ul class="nav-sub" *ngIf="childrenSubItem.children">
                                    <li class="nav-sub-item" *ngFor="let childrenSubItem1 of childrenSubItem.children"
                                        [ngClass]="{'show': childrenSubItem1.active}">
                                        <a class="nav-sub-link"
                                            [routerLink]="!childrenSubItem1.type ? null : [childrenSubItem1.path]"
                                            *ngIf="childrenSubItem1.type === 'link'">
                                            {{childrenSubItem1.title}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>


                    </li>
                </ul>

            </li>
        </ul>

    </div>
</div>

<!--End  Horizonatal menu-->