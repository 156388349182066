import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { isNumber } from 'util';

@Component({
  selector: 'app-textarea',
  templateUrl: `./textarea.component.html`,
  styleUrls: ['./textarea.component.scss'],
})
export class TextAreaComponent {

    @Input() required:boolean = true;
    @Input() type: string = "text";
    @Input() id: string = "";
    @Input() placeholder: string = "";
    @Input() value: any = "";
    @Input() class: string = "form-control";
    
    @Input() inputModel: string;
    @Output() inputModelChange = new EventEmitter<string>();

    @Input() validation:boolean = false;
    @Output() onValidatorChange = new EventEmitter<string>();

    public is_required: boolean = false;

    constructor(){

    }

    validate($event: Event, form:any){}

}