<ng-template #miniProfileModel let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">Date you are Working/Worked</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="card custom-card">
            <div class="">
                <div class="main-content-body main-content-body-contacts">
                    <div class="main-contact-info-header pt-3">
                        <div class="media">
                            <div class="main-img-user">
                                <img alt="" src="{{ user.image }}"> <a href="javascript:;"><i class="fe fe-camera"></i></a>
                            </div>
                            <div class="media-body">
                                <h4>{{ user.name }}</h4>
                                <p *ngIf="user.profile">{{ user.profile.job_title }}</p>
                                <p *ngIf="!user.profile">Title Not Available</p>
                                <!-- <nav class="contact-info">
                                    <a href="javascript:;" class="contact-icon border tx-inverse mr-1" placement="top" ngbTooltip="Call"><i class="fe fe-phone"></i></a>
                                    <a href="javascript:;" class="contact-icon border tx-inverse mr-1" placement="top" ngbTooltip="Video"><i class="fe fe-video"></i></a>
                                    <a href="javascript:;" class="contact-icon border tx-inverse mr-1" placement="top" ngbTooltip="message"><i class="fe fe-message-square"></i></a>
                                    <a href="javascript:;" class="contact-icon border tx-inverse mr-1" placement="top" ngbTooltip="Add to Group"><i class="fe fe-user-plus"></i></a>
                                    <a href="javascript:;" class="contact-icon border tx-inverse" placement="top" ngbTooltip="Block"><i class="fe fe-slash"></i></a>
                                </nav> -->
                            </div>
                        </div>
                        <!-- <div class="main-contact-action btn-list pt-3 pr-3">
                            <a href="javascript:;" class="btn ripple btn-primary text-white btn-icon" container="body" placement="top" ngbTooltip="Edit Profile"><i class="fe fe-edit"></i></a>
                            <a href="javascript:;" class="btn ripple btn-secondary text-white btn-icon" container="body" placement="top" ngbTooltip="Delete Profile"><i class="fe fe-trash-2"></i></a>
                        </div> -->
                    </div>
                    <div class="main-contact-info-body">
                        <!-- <div>
                            <h6>Biography</h6>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                        </div> -->
                        <div class="media-list" *ngIf="user.profile">
                            <div class="media">
                                <div class="media-body">
                                    <div>
                                        <label>PIN Number</label> <span class="tx-medium">{{ user.profile.paramedic_number || "Not Provided" }}</span>
                                    </div>
                                    <div>
                                        <label>System ID</label> <span class="tx-medium"> {{ user.identifier }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="media">
                                <div class="media-body">
                                    <div>
                                        <label>Allergies</label> <span class="tx-medium"> {{ user.profile.allergies || "Not Provided" }}</span>
                                    </div>
                                    <div>
                                        <label>Medication</label> <span class="tx-medium">{{ user.profile.medication || "Not Provided" }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="media">
                                <div class="media-body">
                                    <div>
                                        <label>Current Address</label> <span class="tx-medium">{{ user.profile.address || "Not Provided" }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="media mb-0">
                                <div class="media-body">
                                    <div>
                                        <label>Next Of Kin</label> <span class="tx-medium">{{ user.profile.next_of_kin || "Not Provided"}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-list" *ngIf="!user.profile">
                            <p class="text-center m-5">No profile information provided</p>
                        </div>
                    </div>
                    <div class="main-contact-info-body">

                        <div class="demo-gallery">

                                            
                            <ul id="lightgallery" class="list-unstyled row row-sm">
                                
                                <li class="col-sm-6 col-lg-4" *ngFor="let img of GalleryImage">
                                    <a href="javascript:void(0)" class="wd-100p">
                                        <img class="img-responsive mb-4 wd-100p" [src]="img.modal.img"
                                            alt="Thumb" (click)="openImageModalRowDescription(img)">
                                    </a>
                                </li>
                            </ul>
                            <!-- /Gallery -->
                        </div>
                        <ks-modal-gallery [id]="1" [modalImages]="GalleryImage"
                            [plainGalleryConfig]="customPlainGalleryRowDescConfig"
                            [currentImageConfig]="{downloadable : true}"
                            [buttonsConfig]="buttonsConfigCustom">
                        </ks-modal-gallery>

                    </div>
                </div>
            </div>
        </div>
        
    </div>

</ng-template>

<button style="display: none;" class="btn btn-sm btn-primary openPreview" (click)="open(miniProfileModel)">View Profile</button>