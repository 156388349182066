export class AddReportForm {

    job_id: number 
    senior_staff_id: number
    date: string 
    paramedics: number 
    technicians: number 
    clock_in_time: string 
    clock_out_time: string
    callouts:number 
    ear_pugs: number 
    ambulances: number 
    arena_staff: number
    catering: number 
    event_staff: number
    competitor: number 
    visitor: number 
    use_of_o2: string 
    use_of_entonox: string
}