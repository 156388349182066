import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class JobReportService extends AppService {

    all(callback){

        this.get(`fetch/job/report/list`).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

       },error => callback(error, false));
    }

    send(body:any, callback)
    {

      body.created_by_id = 1;

      this.post(`create/job/report/record`, body).subscribe(response => {

        let status =  false

        if(response.status == "success"){
            status = true
        }

        callback(response.data, status);

     },error => callback(error, false));
    }

    update(body:any, callback){

      this.post(`update/job`, body).subscribe(response => {

        let status =  false
        if(response.status == "success"){
            status = true
        }
        callback(response.data, status);

     },error => callback(error, false));
    }
}
