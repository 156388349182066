import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { WebService } from 'src/app/shared/services/web/web.service';
import { isNumber } from 'util';

@Component({
  selector: 'element-editor',
  templateUrl: `./text-editor.component.html`,
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent {

  @Input() label:string;
    @Input() required:boolean = true;
    @Input() type: string = "text";
    @Input() id: string = "";
    @Input() placeholder: string = "";
    @Input() value: any = "";
    @Input() class: string = "form-control";

    @Input() inputModel: string;
    @Output() inputModelChange = new EventEmitter<string>();


    @Input() validation:boolean = false;
    @Output() onValidatorChange = new EventEmitter<string>();

    public is_required: boolean = false;

    public is_showing: boolean = true;

    constructor(private webService: WebService){

    }

    validate($event: Event, form:any){


    }

    logChange($event: any){

        let $this = this

        if($event.html == undefined || $event.html == ""){
            return;
        }

        if(this.id == undefined || this.id == ""){
           return;
        }

        let body:any = {
            target: {id: this.id},
            data: {value: $event.html}
        }

        this.webService.update(`element`, body, function(result, status){

          // console.log("Response Data for Groups:: ", result, status)

          if(status){
              // $this.groups = result
          }

        })
    }

    onDelete(){

      let $this = this;

      this.webService.delete(`element`, this.id, function(result, status){

        // console.log("Response Data for Groups:: ", result, status)

        if(status){
            $this.is_showing = false
        }

      })
    }

}
