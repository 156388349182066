<div class="" *ngIf="is_showing">
    <a href="javascript:;" style="float: right;" (click)="onDelete()" class="btn pull-right"><i class="ti ti-trash"></i> </a>
    <label> {{ label }} </label>
    <quill-editor [(ngModel)]="inputModel" (onContentChanged)="logChange($event)">
        <div quill-editor-toolbar>
            <span class="ql-formats">
                <select class="ql-font">
                    <option selected></option>
                    <option value="serif"></option>
                    <option value="monospace"></option>
                </select>
                <select class="ql-size">
                    <option value="small"></option>
                    <option selected></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                </select>
            </span>
            <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
            </span>
            <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
            </span>
            <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <select class="ql-align">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                </select>
            </span>
            <span class="ql-formats">
                <button class="ql-link"></button>
                <button class="ql-image"></button>
            </span>
        </div>
    </quill-editor>
</div>