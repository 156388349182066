import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserRecord } from 'src/app/shared/models/auth.models';
import { ChannelGroup, ChannelMessage } from 'src/app/shared/services/channel/chat.model';
import { ChatService } from 'src/app/shared/services/channel/chat.service';
import { DateService } from 'src/app/shared/services/date.service';
import { UserService } from 'src/app/shared/services/users/user.service';
import { Message, Session } from '../chat.model';
import { Participant } from './participant.model';

@Component({
  selector: 'chat-invite',
  templateUrl: './chat-invite.component.html',
  styleUrls: ['./chat-invite.component.scss']
})


/**
 *
 *    Events needed for the chat to work:
 *    1. thread listener: This is thread listener `thread_${this.sourceid}` output will be thread_1 (sourceid is the record id)
 *    2.
 *
 *    this.session.id = this.job_session_id
      this.session.state = true;
      this.session.status = 1
      this.session.group_name = this.job_name;
      this.session.targetid = this.job_identifier;
      this.session.is_group = this.GROUP_KEY
      this.session.messages = new Array<Message>()
 *
 *    This is the history fetcher that load all previous histories from the server
 *    this.chatService.history(this.sourceid, target, 1,  "history_broadcaster");
 *
 *
 *
 *
 */
export class ParticipantsComponent implements OnInit, AfterViewInit {

  @Input() identifier: any;
  @Input() name:any;
  @Input() owner_id: number;

  constructor(
    private viewScroller: ViewportScroller,
    private userService: UserService,
    private toastr: ToastrService,
    private dateService: DateService) {


    }

  ngAfterViewInit(){

  }

  ngOnInit(): void {




  }

  public participants: Array<Participant> = new Array<Participant>()
  public users: Array<UserRecord> = new Array<UserRecord>()
  public isShowing: boolean = false;
  openWindow(){

      this.isShowing = true;
      this.loadUsers();
      this.loadParticipants();
  }

  closeWindow(){

    this.isShowing = false;
    this.participants = new Array<Participant>()
    this.users = new Array<UserRecord>()
  }


  loadParticipants(){

      this.userService.participants({owner_id: this.owner_id}).subscribe(response => {

        if(response.status == "success"){
            this.participants = response.data._participant
        }
        // // console.log("Participant Fetch", this.participants, response)
      }, error => this.toastr.error("Unable to Load remote data"))
  }

  loadUsers(){

    this.userService.users({owner_id: this.owner_id}).subscribe(response => {
      if(response.status == "success"){
          this.users = response.data
      }
    }, error => this.toastr.error("Unable to Load remote data"))
}

addParticipant(id: number){

    let payload:any = {
        owner_id: this.owner_id,
        user_id: id,
        status: 1
    }

    this.userService.addChatParticipant(payload).subscribe(response => {

        if(response.status == "success"){

          this.participants = new Array<Participant>()
          this.users = new Array<UserRecord>()
          this.openWindow()

          this.toastr.success("User was assigned successfully")
        }
    }, error => this.toastr.error("Unable to assign this user"))
}

removeParticipant(id: number){

  let payload:any = {
      owner_id: this.owner_id,
      user_id: id
  }
  this.userService.removeChatParticipant(payload).subscribe(response => {
    if(response.status == "success"){

      this.participants = new Array<Participant>()
      this.users = new Array<UserRecord>()
      this.openWindow()

      this.toastr.success("User was removed successfully")
    }
  }, error => this.toastr.error("Unable to remove this user"))
}

}
