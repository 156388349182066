<ng-template #openAssignNewSeniorStaffModalButton let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">Assign Senior Staff</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="form-group row">

            <div class="col-lg-12 mt-4">
                <p>
                    Assign a senior staff to this job and the user will be able to send report as email.
                </p>
            </div>
            <div class="col-lg-12 mt-4">
                <div class="form-group">
                    <label>Select user to assign:</label>
                    <ng-select class="form-control p-0" [(ngModel)]="staff_id">
                        <ng-option *ngFor="let data of users; let i = index" value="{{ data.user.id }}">
                            {{ data.user.name }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>

        </div>

    </div>
    <div class="modal-footer">
        <button class="btn ripple btn-primary" type="button" (click)="onSubmit($event)">Assign</button>
    </div>

</ng-template>

<button id="openAssignNewSeniorStaffModalButton" [hidden]="true" data-toggle="modal" (click)="openPopUPModal(openAssignNewSeniorStaffModalButton)" data-target="#myModal">Open Modal</button>
