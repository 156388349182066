<div class="page main-signin-wrapper">

    <!-- Row -->
    <div class="row signpages text-center">
        <div class="col-md-12">
            <div class="card">
                <div class="row row-sm">
                    <div class="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                        <div class="mt-5 pt-2 text-center p-2 pos-absolute fill">
                           
                                <img src="../../assets/img/logo.png" style="width:180px;display:block;
                                margin:auto;" class="header-brand-img mb-4" alt="logo">
                            
                            <div class="clearfix"></div>
                            <img src="../../assets/img/svgs/user.svg" class="ht-100 mb-0" alt="user">
                            <h5 class="mt-4 text-white">Medical Solutions Ltd</h5>
                            <span class="tx-white-6 tx-13 mb-5 mt-xl-0">

                                 We have a wide range of <br />
                                 ambulances at our disposal. Professionals  <br />
                                 including, Doctors, Nurses, Paramedics, <br />
                                 Ambulance Technicians FPOS.
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                        <div class="container-fluid">
                            <div class="row row-sm">
                                <div class="card-body mt-2 mb-2">
                                    <!-- <img src="../../assets/img/logo.png" class=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo"> -->
                                    <div class="clearfix"></div>
                                    <form [formGroup]="loginForm" (ngSubmit)="Submit()" novalidate>
                                        <h5 class="text-left mb-2 mt-5">Signin to Your Account</h5>
                                        <p class="mb-4  text-muted tx-13 ml-0 text-left">
                                            Sign In to manage staff day to day activities
                                        </p>
                                        <!--<ngb-alert type="primary" [dismissible]="false">
                                             <span class="alert-inner--text">Use account <strong>spruha@demo.com</strong> and password <strong>spruha</strong> to continue.</span> 
                                        </ngb-alert>-->
                                        <div class="form-group text-left">
                                            <label>Username</label>
                                            <input class="form-control" formControlName="username" type="text">
                                            <div *ngIf="(loginFormControl.username.touched || submitted) && (loginFormControl.username.errors?.required)"
                                                class="tx-danger">
                                                <!-- <small>Enter spruha@demo.com to login</small> -->
                                            </div>
                                        </div>
                                        <div class="text-left ml-0 pull-right">
                                            <div class="mb-1"><a routerLink="/custom/forgot-password" href="javascript:;">Forgot password?</a></div>
                                        </div>
                                        <div class="form-group text-left">

                                            <label>Password</label>
                                            <input class="form-control" formControlName="password" type="password">
                                            <div *ngIf="(loginFormControl.password.touched || submitted) && (loginFormControl.password.errors?.required)"
                                                class="tx-danger">
                                                <small>Enter above password</small>
                                            </div>
                                        </div>
                                        <button class="btn ripple btn-main-primary btn-block"
                                            [disabled]="!loginForm.valid">Sign In</button>
                                            <div class="form-group text-center mt-2" *ngIf="isloading">
                                                <div class="spinner-border spinner-border-sm" role="status">
                                                    <span class="sr-only">Loading...</span> 
                                                </div>
                                                Loading...
                                            </div>

                                            <div class="mt-2" *ngIf="isLoginFailed">
                                                Invalid Username or Password
                                            </div>
                                    </form>

                                    <div class="mt-5"></div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->

</div>