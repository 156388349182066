import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class WebService extends AppService {

    all(target, callback){
        
        this.get(`web/pages/fetch/${target}`, {}).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

       }, (error: HttpErrorResponse) => callback(error, false));
    }

    create(target, body = {}, callback){
        
        this.post(`web/pages/create/${target}`, body).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

      }, (error: HttpErrorResponse) => callback(error, false));
    }

    update(target, body = {}, callback){
        
        this.post(`web/pages/update/${target}`, body).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

      }, (error: HttpErrorResponse) => callback(error, false));
    }

    delete(target, identifier, callback){
        
      this.get(`web/pages/delete/${target}/${identifier}`, {}).subscribe(response => {

        let status =  false
        if(response.status == "success"){
            status = true
        }
        callback(response.data, status);

     },(error: HttpErrorResponse) => callback(error, false));
  }

  fetch(target, identifier, callback){
        
      this.get(`web/pages/fetch/${target}/${identifier}`, {}).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

      },(error: HttpErrorResponse) => callback(error, false));
    }

    images(callback){
        
      this.get(`web/pages/images/list`, {}).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

      },(error: HttpErrorResponse) => callback(error, false));
    }

    search(target, body, callback){
        
      this.post(`web/pages/search/${target}/`, body).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

      },(error: HttpErrorResponse) => callback(error, false));
    }

}
