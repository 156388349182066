import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { WebService } from 'src/app/shared/services/web/web.service';
import { isNumber } from 'util';

@Component({
  selector: 'element-input',
  templateUrl: `./text-input.component.html`,
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent {

  @Input() label:string;
    @Input() id: string = "";
    @Input() value: any = "";
    @Input() class: string = "form-control";

    @Input() inputModel: string;
    @Output() inputModelChange = new EventEmitter<string>();

    @Input() validation:boolean = false;
    @Output() onValidatorChange = new EventEmitter<string>();

    public is_required: boolean = false;

    public is_showing: boolean = true;

    constructor(private webService: WebService){

    }

    validate($event: Event, form:any){


    }

    logChange(data:any ){

      let $this = this

      if(data == undefined || data == ""){
          return;
      }

      if(this.id == undefined || this.id == ""){
         return;
      }

      let body:any = {
          target: {id: this.id},
          data: {value: data}
      }

      this.webService.update(`element`, body, function(result, status){

        // console.log("Response Data for Groups:: ", result, status)

        if(status){
            // $this.groups = result
        }

      })
    }

    onDelete(){

      let $this = this;

      this.webService.delete(`element`, this.id, function(result, status){

        // console.log("Response Data for Groups:: ", result, status)

        if(status){
            $this.is_showing = false
        }

      })
    }

}
