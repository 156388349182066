
export class ChatPayload 
{
    public source!: any
    public target!: string 
    public message!: string
    public data!: any 
    public channel!: any
    public action!: string 
}

export class ChannelMessage 
{
    public id!: number
    public channel_id!: number
    public message!: string
    public source!: string
    public status!: number
    public target!: string
    public updated_at!: string
    public created_at!: string
    public _state!: number
}

export class Channel 
{
    id: number;
    channel: string;
    target: string;
    source: string;
    is_group: number;
    status: number;
    created_at: string;
    updated_at: string;
    messages: ChannelMessage[];
}

export class ChannelGroup 
{
    id: number;
    channel_id: number;
    owner_id: number;
    name: string;
    status: number;
    created_at: string;
    updated_at: string;
    _channel: Channel;
}