<div class="inline-chat-frame" *ngIf="isShowing">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card custom-card">
                <div class="main-content-app pt-0">
                    <div class="main-content-body main-content-body-chat">
                        <div class="main-chat-header pt-3">
                            <div class="card-header border-bottom-0 pt-0 pl-0 pr-0 d-flex">
                                
                                <div class="panel-header">
                                    <h4 class="mb-1 header-text">{{ name }}</h4> 
                                    <!-- <span class="d-block tx-12 text-muted">Invite Staff to chat</span> -->
                                </div>

                                <div class="" (click)="closeWindow()">
                                    <a href="javascript:;" class="option-dots" aria-haspopup="true"
                                        aria-expanded="false"><i class="fe fe-chevron-down"></i>
                                    </a>
                                </div>

                            </div>
                        </div><!-- main-chat-header -->
                        <div class="main-chat-body" id="UserList" style="padding-bottom: 50px;">
                            <div class="content-inner">
                                
                                <table class="table table-hover m-b-0 transcations mt-2">
                                    <tbody>

                                        <tr>
                                            <td colspan="3">
                                                Participants
                                                <hr />
                                            </td>
                                        </tr>
                
                                        <tr *ngFor="let data of participants; let i = index" routerLink="/users/{{data._user.id}}/details">
                                            <td class="wd-5p">
                                                <div class="main-img-user avatar-md">
                                                    <img alt="" class="rounded-circle mr-3" src="{{ data._user.image }}">
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex align-middle ml-3">
                                                    <div class="d-inline-block">
                                                        <h6 class="mb-1">{{ data._user.name }}</h6>
                                                        <p class="mb-0 tx-13 text-muted">{{ data._user.phone }}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-right">
                                               <button (click)="removeParticipant(data._user.id)" class="btn btn-sm btn-default"><i class="fe fe-x text-danger"></i></button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="3">
                                                All Staff
                                                <hr />
                                            </td>
                                        </tr>

                                        <tr *ngFor="let data of users; let i = index" routerLink="/users/{{data.id}}/details">
                                            <td class="wd-5p">
                                                <div class="main-img-user avatar-md">
                                                    <img alt="" class="rounded-circle mr-3" src="{{ data.image }}">
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex align-middle ml-3">
                                                    <div class="d-inline-block">
                                                        <h6 class="mb-1">{{ data.name }}</h6>
                                                        <p class="mb-0 tx-13 text-muted">{{ data.phone }}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-right">
                                               <button (click)="addParticipant(data.id)" class="btn btn-sm btn-default"><i class="fe text-success fe-user-plus"></i></button>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                                <!-- Show User List Here -->
                            
                            </div>
                        </div>
                        
                </div>
            </div>
        </div>
    </div>
</div>