import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService extends AppService {

    fetch(id){
        return this.get(`manage/fetch/participant`);
    }

    list(id){
        return this.get(`manage/list/participant/${id}`);
    }

    find(options){
      return this.post(`manage/find/participant`, options);
  }

    delete(id){
        return this.get(`manage/delete/participant/${id}`);
    }

    update(options){
        return this.post(`manage/update/participant`, options);
    }

    create(options){
      return this.post(`manage/create/participant`, options);
  }

  filter(userid){
    return this.get(`manage/filter/participant/${userid}`);
  }
}
