<ng-template #addReportModal let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">New Report</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">        

        <div class="form-group row">

            <div class="col-lg-12 mt-4" *ngIf="!jobid">
                <div class="form-group">
                    <label>Event Name:</label>
                    <ng-select class="form-control p-0" (change)="onSelected($event, 'job')">
                        <ng-option *ngFor="let data of jobs; let i = index" value="{{ data.id }}">
                            {{ data.name }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                
                <label> Event Date </label>
                <app-input 
                    [type]="'datetime-local'"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.date"
                    [placeholder]="'Date of Event'"
                    [(inputModel)]="form.date">
                </app-input>

            </div>
          
            <div class="col-lg-6 mt-4">
                <div class="form-group">
                    <label>Senior Staff:</label>
                    <ng-select class="form-control p-0" (change)="onSelected($event, 'user')">
                        <ng-option *ngFor="let data of users; let i = index" value="{{ data.id }}">
                            {{ data.name }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="col-lg-12 mt-4">
                <h6> Staff On Duty </h6>
            </div>
           

            <div class="col-lg-6 mt-4">
                
                <label> No. of Paramedics </label>
                <app-input 
                    [type]="number"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.paramedics"
                    [placeholder]="'0'"
                    [(inputModel)]="form.paramedics">
                </app-input>

            </div>

            <div class="col-lg-6 mt-4">
                
                <label> No. of Technicians </label>
                <app-input 
                    [type]="number"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.technicians"
                    [placeholder]="'0'"
                    [(inputModel)]="form.technicians">
                </app-input>

            </div>

            <div class="col-lg-12 mt-4">
                <h6> Service Time </h6>
            </div>
            
            <div class="col-lg-6 mt-4">
                
                <label> Clock In Time </label>
                <app-input 
                    [type]="'datetime-local'"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.clock_in_time"
                    [placeholder]="'Clock In Time'"
                    [(inputModel)]="form.clock_in_time">
                </app-input>

            </div>

            <div class="col-lg-6 mt-4">
                
                <label> Stand Down Time </label>
                <app-input 
                    [type]="'datetime-local'"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.clock_out_time"
                    [placeholder]="'Clock Out Time'"
                    [(inputModel)]="form.clock_out_time">
                </app-input>

            </div>

            <div class="col-lg-4 mt-4">
                
                <label> Number of Callouts </label>
                <app-input 
                    [type]="'number'"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.callouts"
                    [placeholder]="'0'"
                    [(inputModel)]="form.callouts">
                </app-input>

            </div>

            <div class="col-lg-4 mt-4">
                
                <label> Ear plugs </label>
                <app-input 
                    [type]="number"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.ear_pugs"
                    [placeholder]="'0'"
                    [(inputModel)]="form.ear_pugs">
                </app-input>

            </div>

            <div class="col-lg-4 mt-4">
                
                <label> No of ambulances </label>
                <app-input 
                    [type]="number"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.ambulances"
                    [placeholder]="'0'"
                    [(inputModel)]="form.ambulances">
                </app-input>

            </div>

            <div class="col-lg-12 mt-4">
                <h6> Attendees</h6>
            </div>


            <div class="col-lg-4 mt-4">
                
                <label> Arena staff</label>
                <app-input 
                    [type]="'number'"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.arena_staff"
                    [placeholder]="'0'"
                    [(inputModel)]="form.arena_staff">
                </app-input>

            </div>

            <div class="col-lg-4 mt-4">
                
                <label> Catering staff </label>
                <app-input 
                    [type]="'number'"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.catering"
                    [placeholder]="'0'"
                    [(inputModel)]="form.catering">
                </app-input>

            </div>

            <div class="col-lg-4 mt-4">
                
                <label> Event staff </label>
                <app-input 
                    [type]="'number'"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.event_staff"
                    [placeholder]="'0'"
                    [(inputModel)]="form.event_staff">
                </app-input>

            </div>

            <div class="col-lg-6 mt-4">
                
                <label> Competitor </label>
                <app-input 
                    [type]="'number'"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.competitor"
                    [placeholder]="'0'"
                    [(inputModel)]="form.competitor">
                </app-input>

            </div>

            <div class="col-lg-6 mt-4">
                
                <label> Visitor </label>
                <app-input 
                    [type]="'number'"
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="form.visitor"
                    [placeholder]="'0'"
                    [(inputModel)]="form.visitor">
                </app-input>

            </div>

            <div class="col-lg-6 mt-4">
                <div class="form-group">
                    <label>Use of O2:</label>
                    <ng-select class="form-control p-0" (change)="onSelected($event, 'o2')">
                        <ng-option *ngFor="let data of levels; let i = index" value="{{ data.id }}">
                            {{ data.name }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="form-group">
                    <label>Use of Entonox:</label>
                    <ng-select class="form-control p-0" (change)="onSelected($event, 'entonox')">
                        <ng-option *ngFor="let data of levels; let i = index" value="{{ data.id }}">
                            {{ data.name }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>


            <!-- <div class="col-lg-12 mt-4">
                
                <label> Message </label>
                <app-textarea 
                    [class]="'form-control'" 
                    [id]="'name'"
                    [value]="newNessage.message"
                    [placeholder]="'Enter Job Name'"
                    [(inputModel)]="newNessage.message">
                </app-textarea>

            </div> -->


        </div>

    </div>
    <div class="modal-footer">
        <button class="btn ripple btn-primary" type="button" (click)="onSubmit($event)">Create Now</button>
        <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
    </div>

</ng-template>

<button id="openModalButton" [hidden]="true" data-toggle="modal" (click)="openPopUPModal(addReportModal)" data-target="#myModal">Open Modal</button>
