import { User, UserList } from "src/app/shared/models/auth.models"

export class Session {
    id: number
    group_name?: string
    identifier: string
    is_group: number
    targetid: string 
    sourceid: string
    messages: Array<Message> = new Array<Message>()
    status: number
    created_at: string
    updated_at:string
    state:boolean
    user: UserList = new UserList()
}

export class Message {

    id: number
    message:string
    created_at:string
    file_name: string
    image_code: string
    session_id: string
    sourceid: string
    targetid: string
    updated_at: string
    status: number
    user: UserList = new UserList()
}