import { Injectable, OnDestroy } from '@angular/core';
import { io } from 'socket.io-client';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { audio_str } from './sound';
import { AuthenticationService } from '../auth.service';

@Injectable({
    providedIn: 'root'
  })
export class ChatService {
    private url =  environment.socket
    private socket;

    constructor(private authService: AuthenticationService) {

        try{
            this.socket = io(this.url, { transports: ['websocket', 'polling', 'flashsocket'], query:{target: this.getIdentifier(), token: this.authService.token()} });
            // this.socket = io(this.url);
        }catch(e){

        }
    }

    getIdentifier(){
        return this.authService.identifier()
    }

    public send(data:any) {

        try{
            if(data.target && data.source){
                this.socket.emit("thread_ambulance_thrunk", data);
                return true;
            }
            return false;
        }catch(e){
            return false;
        }
    }

    disconnect(target){

        this.socket.off(target, function(data){ })
        this.socket.removeAllListeners(target);
    }

    connect(target) {

        return Observable.create(observer => {

            this.socket.on(target, function(data){
                // // console.log("Received message from Websocket Server")
                observer.next(data);
            })

        });
    }

    public socketExceptions = () =>{


        this.socket.on('connect_error', function(err) {
            // handle server error here
            // console.log('Error connecting to server');
        });
    }

    /**
     *
     * @returns audio instance that can be payed
     * audioElement.play();
     */
    public audio(){

        var audioElement = new Audio();
        audioElement.src = audio_str
        return audioElement
    }

    public notify(data){

    }

}
