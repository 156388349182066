import { ToastrService } from 'ngx-toastr';
import { JobAccessor } from './../../../../shared/services/jobs/job.accessor';
import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, AfterViewInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AddReportForm } from 'src/app/components/reporting/service-report/report.model';
import { UserList } from 'src/app/shared/models/auth.models';
import { Job, Report, Assigned } from 'src/app/shared/models/job.model';
import { DateService } from 'src/app/shared/services/date.service';
import { JobReportService } from 'src/app/shared/services/jobs/job.report.service';
import { JobService } from 'src/app/shared/services/jobs/job.service';
import { UserService } from 'src/app/shared/services/users/user.service';
import { UserAssigned } from 'src/app/components/jobs/details/details.model';

@Component({
  selector: 'assign-senior-staff',
  templateUrl: './assign-senior-staff.component.html',
  styleUrls: ['./assign-senior-staff.component.scss']
})

/*
 *
 */
export class AssignSeniorStaffComponent implements OnInit, AfterViewInit {

  @Output() onSuccessResponse: EventEmitter<any> = new EventEmitter();
  @Input() eventid: number;

  public users: Array<UserAssigned> = new Array<UserAssigned>()
  public staff_id: number;

    constructor(
      private modal: NgbModal,
      private reportService: JobReportService,
      private jobAccessor: JobAccessor,
      private toastr: ToastrService)
      {

    }

    ngAfterViewInit(){

    }



    ngOnInit(): void {

      this.jobAccessor.assigned$.subscribe(response => {
         if(!response) { return; }
          this.users = response
      })

      if(!this.eventid){ return; }
      this.jobAccessor.getAssignedList({ where: {job_id: this.eventid}})

    }

    open(){

        let doc:any = document.querySelector("#openAssignNewSeniorStaffModalButton")
        doc.click();
    }

    openPopUPModal(addReportModal){

        this.modal.open(addReportModal, {size: 'md'})
    }

    onSubmit(){

    if(!this.staff_id || !this.eventid){ return; }

    const body:any = {}
    body.target = {job_id: this.eventid}
    body.data = {senior_staff_id: this.staff_id}

    this.jobAccessor.updateReport(body).subscribe(response => {

        if(response.status == "success"){
            this.onSuccessResponse.emit(true);
            this.toastr.success("User successfully assigned as senior staff on this Job/Event")
            this.modal.dismissAll()
            return;
        }
        this.toastr.error("Unable to assign this user to this Job/Event")
    })
  }
}
