<!-- Sidebar -->
<div #sidebar class="sidebar sidebar-right sidebar-animate" >
    <div class="sidebar-icon">
        <a href="javascript:void(0)" class="text-right float-right text-dark fs-20" (click)="onClose()">
            <i class="fe fe-x"></i>
        </a>
    </div>
    <div class="sidebar-body" *ngIf="false">
        <h5>Todo</h5>
        <div class="d-flex p-3">
            <label class="ckbox"><input checked  type="checkbox"><span>Hangout With friends</span></label>
            <span class="ml-auto">
                <i class="fe fe-edit-2 text-primary mr-2" placement="top" ngbTooltip="Edit"></i>
                <i class="fe fe-trash-2 text-danger mr-2" placement="top" ngbTooltip="Delete"></i>
            </span>
        </div>
        <div class="d-flex p-3 border-top">
            <label class="ckbox"><input type="checkbox"><span>Prepare for presentation</span></label>
            <span class="ml-auto">
                <i class="fe fe-edit-2 text-primary mr-2" placement="top" ngbTooltip="Edit"></i>
                <i class="fe fe-trash-2 text-danger mr-2" placement="top" ngbTooltip="Delete"></i>
            </span>
        </div>
        <div class="d-flex p-3 border-top">
            <label class="ckbox"><input type="checkbox"><span>Prepare for presentation</span></label>
            <span class="ml-auto">
                <i class="fe fe-edit-2 text-primary mr-2" placement="top" ngbTooltip="Edit"></i>
                <i class="fe fe-trash-2 text-danger mr-2" placement="top" ngbTooltip="Delete"></i>
            </span>
        </div>
        <div class="d-flex p-3 border-top">
            <label class="ckbox"><input checked type="checkbox"><span>System Updated</span></label>
            <span class="ml-auto">
                <i class="fe fe-edit-2 text-primary mr-2" placement="top" ngbTooltip="Edit"></i>
                <i class="fe fe-trash-2 text-danger mr-2" placement="top" ngbTooltip="Delete"></i>
            </span>
        </div>
        <div class="d-flex p-3 border-top">
            <label class="ckbox"><input type="checkbox"><span>Do something more</span></label>
            <span class="ml-auto">
                <i class="fe fe-edit-2 text-primary mr-2" placement="top" ngbTooltip="Edit"></i>
                <i class="fe fe-trash-2 text-danger mr-2" placement="top" ngbTooltip="Delete"></i>
            </span>
        </div>
        <div class="d-flex p-3 border-top">
            <label class="ckbox"><input  type="checkbox"><span>System Updated</span></label>
            <span class="ml-auto">
                <i class="fe fe-edit-2 text-primary mr-2" placement="top" ngbTooltip="Edit"></i>
                <i class="fe fe-trash-2 text-danger mr-2" placement="top" ngbTooltip="Delete"></i>
            </span>
        </div>
        <div class="d-flex p-3 border-top">
            <label class="ckbox"><input  type="checkbox"><span>Find an Idea</span></label>
            <span class="ml-auto">
                <i class="fe fe-edit-2 text-primary mr-2" placement="top" ngbTooltip="Edit"></i>
                <i class="fe fe-trash-2 text-danger mr-2" placement="top" ngbTooltip="Delete"></i>
            </span>
        </div>
        <div class="d-flex p-3 border-top mb-0">
            <label class="ckbox"><input  type="checkbox"><span>Project review</span></label>
            <span class="ml-auto">
                <i class="fe fe-edit-2 text-primary mr-2" placement="top" ngbTooltip="Edit"></i>
                <i class="fe fe-trash-2 text-danger mr-2" placement="top" ngbTooltip="Delete"></i>
            </span>
        </div>
        <h5>Overview</h5>
        <div class="p-4">
            <div class="main-traffic-detail-item">
                <div>
                    <span>Founder &amp; CEO</span> <span>24</span>
                </div>
                <div class="progress">
                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="20" class="progress-bar progress-bar-xs wd-20p" role="progressbar"></div>
                </div><!-- progress -->
            </div>
            <div class="main-traffic-detail-item">
                <div>
                    <span>UX Designer</span> <span>1</span>
                </div>
                <div class="progress">
                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="15" class="progress-bar progress-bar-xs bg-secondary wd-15p" role="progressbar"></div>
                </div><!-- progress -->
            </div>
            <div class="main-traffic-detail-item">
                <div>
                    <span>Recruitment</span> <span>87</span>
                </div>
                <div class="progress">
                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="45" class="progress-bar progress-bar-xs bg-success wd-45p" role="progressbar"></div>
                </div><!-- progress -->
            </div>
            <div class="main-traffic-detail-item">
                <div>
                    <span>Software Engineer</span> <span>32</span>
                </div>
                <div class="progress">
                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" class="progress-bar progress-bar-xs bg-info wd-25p" role="progressbar"></div>
                </div><!-- progress -->
            </div>
            <div class="main-traffic-detail-item">
                <div>
                    <span>Project Manager</span> <span>32</span>
                </div>
                <div class="progress">
                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" class="progress-bar progress-bar-xs bg-danger wd-25p" role="progressbar"></div>
                </div><!-- progress -->
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar -->