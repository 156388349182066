import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Images } from 'src/app/components/pages/pages/editor/model/pages.model';
import { WebService } from 'src/app/shared/services/web/web.service';
import { isNumber } from 'util';

@Component({
  selector: 'element-picker',
  templateUrl: `./image-picker.component.html`,
  styleUrls: ['./image-picker.component.scss'],
})
export class ImagePickerComponent {

   @Input() label:string;
    @Input() required:boolean = true;
    @Input() type: string = "text";
    @Input() id: string = "";
    @Input() placeholder: string = "";
    @Input() value: any = "";
    @Input() class: string = "form-control";

    @Input() inputModel: string;
    @Output() inputModelChange = new EventEmitter<string>();

    @Input() validation:boolean = false;
    @Output() onValidatorChange = new EventEmitter<string>();

    public is_required: boolean = false;

    public is_showing: boolean = true;

    constructor(
      private webService: WebService,
      private modalService: NgbModal
      ){

    }

    validate($event: Event, form:any){


    }

    logChange(data:any ){

      let $this = this

      if(data == undefined || data == ""){
          return;
      }

      if(this.id == undefined || this.id == ""){
         return;
      }

      let body:any = {
          target: {id: this.id},
          data: {value: data}
      }

      this.webService.update(`element`, body, function(result, status){

        // console.log("Response Data for Groups:: ", result, status)

        if(status){
            // $this.groups = result
        }

      })
    }

    addImage(newBlock){

        this.modalService.open(newBlock);

        /**
         * Fetch images from server after opening the modal
        */
        this.setImages();

    }

    public images: Array<Images>;

    setImages(){

      let $this = this

      this.webService.images(function(result, status){

        // console.log("Response Data for Images:: ", result, status)

        if(status){
            $this.images = result
        }

      })

    }

    getImages(dirname: string, url: string){

        let path = dirname + "/" + url

        this.inputModel = path

        this.modalService.dismissAll()

        this.logChange( path )

    }

    onDelete(){

      let $this = this;

      this.webService.delete(`element`, this.id, function(result, status){

        // console.log("Response Data for Groups:: ", result, status)

        if(status){
            $this.is_showing = false
        }

      })
    }


}
