import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ChannelGroup, ChannelMessage } from 'src/app/shared/services/channel/chat.model';
import { ChatService } from 'src/app/shared/services/channel/chat.service';
import { DateService } from 'src/app/shared/services/date.service';
import { Message, Session } from '../chat.model';
import {JobService} from '../../../../shared/services/jobs/job.service';
import {ToastrService} from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'copy-chat',
  templateUrl: './copy-chat.component.html',
  styleUrls: ['./copy-chat.component.scss']
})

export class CopyChannelComponent implements OnInit, AfterViewInit {

  @Input() channel: any;

  public new_channel_name: string;

  constructor(
    private viewScroller: ViewportScroller,
    private chatService: ChatService,
    private jobService: JobService,
    private toastr: ToastrService,
    private model: NgbModal,
    private dateService: DateService) {


    }

  ngAfterViewInit(){

  }

  ngOnInit(): void {


  }

  onOpen(){
      let dom:any = document.querySelector("copyModalButton");
      dom.click();
  }

  open(copyModal:any){

      this.model.open(copyModal)
  }

  onCopyLog(){

    const body:any = {}
    body.channel = this.channel
    body.name = this.new_channel_name

    // channelid
      this.jobService.copy(body).subscribe(response => {

        if(response.status == "success"){
            this.toastr.success("Log successfully Copied!")
            this.model.dismissAll();
            return;
        }
        this.toastr.error("Log failed to copy. Please try again!")
      }, error => this.toastr.error("Unable to copy this log"))
  }


}
