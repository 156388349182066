import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { RequestService } from 'src/app/shared/services/request.service';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'idate-normal',
  templateUrl: `./idate-normal.component.html`,
  styleUrls: ['./idate-normal.component.scss'],
})
export class IDateNormalEditorComponent {

    @Input() id: string = ""
    @Input() column: string = ""
    @Input() table: string = ""
    @Input() label: string = ""
    @Input() type: string = "date"

    @Input() inputModel: string;
    @Output() inputModelChange = new EventEmitter<string>();

    constructor(private requestService: RequestService, private dateService: DateService,){

    }

    public onEditMode:boolean = false;
    public date_or_time: string = ""

    saveDate(){

        if(!this.date_or_time){
            // console.log("Response", this.date_or_time)
            return;
        }

        const body:any = {}
        body.data = {}

        let value = this.date_or_time;
        if(this.type == "time"){
            value = `${this.date_or_time}:00`
        }

        body.target         = {id: this.id}
        body.data.key       = this.column
        body.data.value     = value
        body.table          = this.table
        let $this = this

        this.requestService._post("table/data/update", body, function(result, status){

            if(status){
                $this.onEditMode = false
                $this.label = value
                return
            }
            $this.onEditMode = true
        });
    }

    formatDateOrTime(date: string){

      // let d = date.replace(" ", "T")
      // this.date_or_time = `${d}Z`;

      return date;

        if(! date){
            return "0000-00-00 00:00:00"
        }
        if(this.type == "time"){

            return date;
        }
        return date
      }


}
