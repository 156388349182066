<!-- Main Footer-->
<div class="main-footer text-center">
    <div class="container">
        <div class="row row-sm">
            <div class="col-md-12">
                <span>Copyright © {{ today | date: 'y'}}
                    <a href="javascript:void(0)">Medical Solutions</a> | 
                    All rights reserved. Developer: <a href="javascript:void(0)">Phynix Media Ltd</a></span>
            </div>
        </div>
    </div>
</div>
<!--End Footer-->