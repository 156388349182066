import { Component, OnInit, Output, Input, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Image } from '@ks89/angular-modal-gallery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { UserRecord } from 'src/app/shared/models/auth.models';
import { PayloadService } from 'src/app/shared/services/requests/payload.service';
import { UserDocument } from '../../users/details/details.model';

@Component({
  selector: 'mini-profile',
  templateUrl: './mini-profile.component.html',
  styleUrls: ['./mini-profile.component.scss'],
})
export class MiniProfileComponent {

    constructor(private model: NgbModal, private route: ActivatedRoute, private payloadService: PayloadService){

    }

    ngOnInit(){


    }

    ngAfterViewInit(){


    }

    public userid: number;

    initPreview(id: number){

      this.getSelectedUser(id);

      let dom:any = document.querySelector(".openPreview")
      dom.click()

      // console.log("Selected usesr ID:: ", this.userid)
    }

    open(miniProfileModel: any){

      this.model.open(miniProfileModel, {size: "lg"})
    }


  public user: UserRecord = new UserRecord

  public certs: Array<UserDocument> = new Array<UserDocument>()
  public license: Array<UserDocument> = new Array<UserDocument>()

  public GalleryImage: Image[] = []

  getSelectedUser(userid: number){

    let $this = this;

    this.GalleryImage = []

    this.payloadService.request(`user/find`, {where:{id: userid},limit:1}, this.payloadService.TYPE_POST, function(response, status){

       if(status){
          $this.user = response

          let docs = response.document;
          if(docs != undefined){

            docs.forEach(row => {

                let image = new Image( 1, { img: `https://api.ambulance.uk/storage/${row.name}`, title: '', })
                $this.GalleryImage.push(image)

            })
          }
       }

       // console.log("Response: ", $this.user, status, response.image)

    })

  }
}
